import React from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import Logo from '../img/logo_white.png'
import './NavbarNew.css'
import { Link } from "react-router-dom";
import classNames from "classnames";

import { hover } from '@testing-library/user-event/dist/hover';
import { color } from '@mui/system';

const Navbar =()=>{
    
    return(
    
        <div className='navbarnew' id='navbar'>
            <div className='logo'>
                <a href='#home'><img src='' alt='logo'/></a>
            </div>
            <ul className='navm1'>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi1'><a className='a' href='#home'>HOME</a></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi1'><a className='a' href='#about'>ABOUT US</a></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi1'><a className='a' href='#services'>SERVICES</a></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi1'><a className='a' href='#industries'>INDUSTRIES</a></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi1'><a className='a' href='#contact'>CONTACT US</a></li>
            </ul>
            <div className='hamburger1'>
                {/* <FaBars/> */}
                <nav class="navbar navbar-dark bg-dark fixed-top">
                <div class="container-fluid">
                <div className='logo'>
                    <a href='#home'><img src='imgicon/logo_black.png' alt='logo'/></a>
                </div>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-dark navst" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">ALPHAXN</h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#about">About Us</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#services">Services</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#industries">Industries</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                </nav>
            </div>

            {/* content */}
            
        </div>
    )
}

export default Navbar