import React from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import Logo from '../img/logo_white.png'
import './Navbar.css'
// import { Link } from "react-router-dom";
// import { HashLink as Link } from 'react-router-hash-link';
// import { NavLink, Link } from 'react-router-dom';
import classNames from "classnames";
import useSticky from "./useSticky";
import { hover } from '@testing-library/user-event/dist/hover';
import { color } from '@mui/system';
import {useState,useEffect} from "react";
import { NavHashLink } from 'react-router-hash-link';
// import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import * as Scroll from 'react-scroll';
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";



const Navbar =()=>{
    const { sticky, stickyRef } = useSticky();
    
    const [click,setClick] = useState(false)
    const handleClick = () => {
        console.log("yes")
        setClick(!click)
    }

    const closeMenu = () => {console.log("dd") 
        setClick(false)}
    
    return(
    
        <div className={classNames("navbar", { sticky })} id='navbar' ref={stickyRef}>
            <div className='logo'>
                <a href='#home'><img src='imgicon/logo_final.png' alt='logo'/></a>
            </div>
            <ul className='navm'>
                {/* <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi'><Link className='a' to='#home' spy={true} smooth={true} >HOME</Link></li> */}
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff', padding: '1rem'}} ><Link className='a' to='about' activeClass="active colo" spy><b>ABOUT US</b></Link></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff', padding: '1rem'}} ><Link className='a' to='services' activeClass="active colo" spy><b>SERVICES</b></Link></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff', padding: '1rem'}} ><Link className='a' to='industries' activeClass="active colo" spy><b>INDUSTRIES</b></Link></li>
                <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff', padding: '1rem'}} ><Link className='a' to='contact' activeClass="active colo" spy><b>CONTACT US</b></Link></li>
            </ul>
            <div className='hamburger'>
                {/* <FaBars/> */}
                <nav class="navbar navbar-dark bg-dark fixed-top">
                <div class="container-fluid">
                <div className='logo'>
                    <a href='#home'><img src='imgicon/logo_final.png' alt='logo'/></a>
                </div>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-dark navst" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">ALPHAXN</h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" id="close-button"></button>
                    </div>
                    <div class="offcanvas-body">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        {/* <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#home">Home</a>
                        </li> */}
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#about" >About Us</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#services">Services</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#industries">Industries</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                </nav>
            </div>
            {sticky && (
            <div
                style={{
                height: '${stickyRef.current?.clientHeight}px'
                }}
            />
            )}

            {/* content */}
            
        </div>
    )
}

export default Navbar