import 'animate.css'
import NewTab from './comp/NewTab';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './comp/Home';
import ServicesTab from './comp/ServicesTab';
import ContactTab from './comp/ContactTab';
import ScrollToTop from './comp/ScrollToTop';
import CookiePage from './comp/CookiePage';

function App() {  

  return (
    <div>
      <BrowserRouter>

      {/* <Home/> */}

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/aboutus" element={<NewTab/>}/>
        <Route path="/services" element={<ServicesTab/>}/>
        <Route path="/contact" element={<ContactTab/>}/>
        <Route path="/cookie-policy" element={<CookiePage/>}/>
      </Routes>


      </BrowserRouter>
    </div>
  );
}

export default App;