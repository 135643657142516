import React from 'react'
import cookies from './cookie.css'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';

const Cokies = () => {
    const [showcookie, setShowcookie] = useState(false);

    const handleClickAgree = () => {
        Cookies.set('name', 'value', { expires: 7 });
        setShowcookie(false);
    }

    const handleClickCancel = () => {
        setShowcookie(false);
    }

    useEffect(() => {
        if (!Cookies.get('name')) {
            setShowcookie(true)
        };
    }, []);


    return (
        <div>
            {showcookie &&
                <div className="cookie-container">
                    <p className="cookie-text">
                        This website uses cookies to help us give you the best experience when you visit. By using this website you consent to our use of cookies. For more information please review our cookie policy. <Link to='/cookie-policy'> <b>Cookie Policy</b></Link>
                    </p>
                    <div className="agree">
                        <button onClick={handleClickAgree}>I Agree</button>
                    </div>
                    <button onClick={handleClickCancel} class="item-5"></button>
                </div>}
        </div>
    )
}

export default Cokies