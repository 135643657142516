// import React from 'react'
import {motion} from 'framer-motion'
// import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import NavbarA from './NavbarA';
import './footer_style.css'
import Testimonials from './Testimonials'

const NewTab = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div id='main'>
        <NavbarA/>
        {/* <header className='headerstyle'>
            <nav className='nav1 container'>
                <div className='logoimg'>
                    <div>
                        <Link to='/'><span className='logostyle'>
                            <span style={{boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px', maxWidth: '100%'}}>
                                <img alt='logo' src='imgicon/logo_black.png' style={{display: 'block',  maxWidth: '100%', width: 'initial', height: 'initial', background: 'none',  opacity: '1',  border: '0px', margin: '0px', padding: '0px'}}></img>
                            </span>
                            <img alt='log' srcSet='imgicon/logo_black.png' style={{position: 'absolute', inset: '0px', boxSizing: 'border-box', padding: '0px', border: 'none', margin: 'auto', display: 'block', width: '0px', height: '0px', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}></img>
                        </span></Link>
                    </div>
                </div>
                <div className='navtabs'>
                    <ul className='tab1'>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link className='a' to='/'>Home</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link className='a' to='/aboutus'>About us</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link className='a' to='/services'>services</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link className='a' to='/#industries'>industries</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link className='a' to='/#contact'>contact us</Link></li>

                    </ul>
                </div>
            </nav>
        </header> */}

        <div className='hamburger1'>
                {/* <FaBars/> */}
                <nav class="navbar navbar-dark bg-dark fixed-top">
                <div class="container-fluid">
                <div className='logo'>
                    <a href='/'><img src='imgicon/logo_final.png' alt='logo'/></a>
                </div>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">ALPHAXN</h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        {/* <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/">Home</Link>
                        </li> */}
                        <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/#about">About Us</Link>
                        </li>
                        <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/#services">Services</Link>
                        </li>
                        <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/#industries">Industries</Link>
                        </li>
                        <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/#contact">Contact Us</Link>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                </nav>
            </div>

        {/* continue here */}
        <main >
            <section className='bannerstyle'>
                <div className='justdiv'>
                    <div className='leftban1'>
                        <h3>
                            <span data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400" style={{paddingRight: '53px',color: 'white',textTransform:'none'}}><b>About Us</b></span>
                            <div style={{ width: '40px', position: 'absolute', top: '50%', right: '0', maxWidth: '40px', height: '1px', backgroundColor: '#fff'}}></div>
                        </h3>
                    </div>
                    <div className='leftbgimg'>
                        <div style={{height: '100%'}}>
                            <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%'}}>
                                <span className='imagebgleft'>
                                    <img alt='city' srcSet='imgicon/city_venice1.webp' className='bgimagecity' decoding='sync'></img>
                                    <motion.img animate={{scale:1.2}} transition={{duration: 3}} alt='city' srcSet='imgicon/city_venice1.webp' className='bgimagecity' decoding='sync'></motion.img>
                                </span>
                            </div>
                            <motion.div initial={{y: -600}} animate={{y: 0}} transition={{duration: 2}} className='transparent1 image'></motion.div>
                        </div>
                        <div className='cornerleft12'></div>
                        <div className='cornerleft12_n'></div>
                    </div>
                    <div className='transparent3 image'></div>
                </div>
                <div style={{position: 'relative', width: '100%', height: '98%', backgroundColor: 'rgb(20,20 ,20)'}}>
                    <div className='rightcontent1'>
                        <span className='spancontent1'>
                            <span>
                            <div data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400">
                                <p className='heading1'>
                                    <span style={{color: 'white', paddingTop: '0px'}}>We provide financial and accounting services to Private Equity and Real Estate funds.</span>
                                </p>
                                <p style={{paddingTop: '1.6em', display: 'flex', overflow: 'hidden',fontFamily: '"Raleway", sans-serif'}}>
                                    <span style={{color: 'white'}}>ALPHAXN team delivers best-in-class service everyday to support the function of Analysts and Accountants.</span>
                                </p>
                            </div>
                            </span>
                        </span>
                    </div>
                    <div className='righttri2'></div>
                </div>
            </section>

                {/* <div class="container-fluid mb-4 container"> */}
                  <div style={{paddingBottom: '5em'}}>
                  <div class="row_n">
                      <div class="col-md-4">
                          <div class="box_n">
                              <div class="our-services_n settings" style={{padding: '0em'}}>
                                  {/* <div class="icon"> <img src='imgicon/icon_grad/1.png'></img> </div> */}
                                  <h4 className='newhead' style={{paddingTop: '1em',paddingBottom: '1em'}}>Your Challenges</h4>
                                  <div className='infobullets'>
                                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Changing business environment</p>
                                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Growing complexities</p>
                                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Staffing and attrition rates</p>
                                    <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '2.5em',paddingLeft: '1.4em',paddingRight: '1.4em'}}>Maintaining a high degree of transparency, timeliness in reporting, and consistency to stakeholders</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div class="col-md-4">
                          <div class="box_n position_box">
                              <div class="our-services_n privacy">
                                  {/* <div class="icon"> <img src='imgicon/icon_grad/4.png'></img> </div> */}
                                  <h4 className='newhead' style={{paddingTop: '1em',paddingBottom: '1em'}}>Our Impact On Your Business</h4>
                                  <div className='infobullets'>
                                  <p style={{fontFamily: '"Raleway", sans-serif'}}>Quick scaling</p>
                                  <p style={{fontFamily: '"Raleway", sans-serif'}}>Fixed costs converted to variable costs</p>
                                  <p style={{fontFamily: '"Raleway", sans-serif'}}>Efficiency gains</p>
                                  <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '1.5em'}}>Overcome talent shortage and attrition problems</p>
                                  {/* <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '1.5em'}}>Quick scaling without increasing fixed costs</p> */}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
              {/* </div> */}

            {/* <section style={{position: 'relative', zIndex: '1'}}>
                <div className='line7'></div>
                <div className='gridcontent container'>
                    <div>
                        
                        <div data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400">
                            <p className='heading1'>
                                <span>Your Challenges</span>
                            </p>
                            <p style={{paddingTop: '1.6em', display: 'flex', overflow: 'hidden', textAlign: 'left',fontFamily: '"Raleway", sans-serif',fontSize: '1.2em'}}>
                                <span><ol style={{ listStyleType: 'disc' }}>
                                    <li style={{paddingBottom: '1em'}}>Changing business environment</li>
                                    <li style={{paddingBottom: '1em'}}>Growing complexities</li>
                                    <li style={{paddingBottom: '1em'}}>Maintaining a high degree of transparency, timeliness in reporting, and consistency to stakeholders</li>
                                    <li style={{paddingBottom: '1em'}}>Staffing and attrition rates</li>
                                </ol>
                                </span>
                            </p>
                        </div>

                    </div>
                    
                    <div>
                        <div data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400">
                            <p className='heading1'>
                                <span>Our Impact On Your Business</span>
                            </p>
                            <p style={{paddingTop: '1.6em', display: 'flex', overflow: 'hidden', textAlign: 'left',fontFamily: '"Raleway", sans-serif',fontSize: '1.2em'}}>
                                <span><ol style={{ listStyleType: 'disc' }}>
                                    <li style={{paddingBottom: '1em'}}>Quick scaling without increasing fixed costs</li>
                                    <li style={{paddingBottom: '1em'}}>Certain fixed costs converted to variable costs</li>
                                    <li style={{paddingBottom: '1em'}}>Efficiency gains</li>
                                    <li style={{paddingBottom: '1em'}}>Timely action</li>
                                </ol>
                                </span>
                            </p>
                        </div>

                    </div>
                    
                    <div></div>
                    
                </div>
            </section> */}
        <Testimonials/>

            <section className='cutimage'>
                <div className='cutimage_au'>
                    <div className='emptybg1'></div>
                    <span className='imagestyle1'>
                        <span className='insidespan'>
                            <img alt='image' src='imgicon/container_g.jpg' decoding='sync' style={{display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px'}}></img>
                        </span>
                        <img alt='city' className='imagejpg' decoding='async' src='imgicon/container_g.jpg'></img>
                    </span>
                </div>
            </section>
        </main>
        <footer className='FooterStyle' style={{backgroundColor: "rgb(53,53,53)", padding: "1.4em 0", backgroundImage: 'url("imgicon/footer_greent.png")', backgroundPosition: 'center'}}>
        <div style={{ width: '90%', margin: '0 auto',fontFamily: '"Raleway", sans-serif'}}>
        <p className='bolder-text' style={{color: '#000', fontSize: '15px', textAlign: 'center',fontWeight: 'bold'}}>VeSigma LLC (dba ALPHAXN)</p>
            <p style={{color: '#484848', fontSize: '15px', textAlign: 'center',fontWeight: '600'}}>Copyright © 2022 ALPHAXN. All rights reserved.</p>
            <p style={{color: '#484848', fontSize: '12px', textAlign: 'center',fontWeight: '600'}}><Link to='/cookie-policy' style={{color: '#484848'}}>Cookie Policy</Link></p>
            <p style={{color: '#484848', fontSize: '15px', textAlign: 'center',fontWeight: '600'}}>Site by <a style={{color: '#484848'}} href='https://sirtogo.com/' target="_blank">sirtogo.com</a></p>
        </div>
        </footer>
        {/* <footer className='FooterStyle' style={{backgroundColor: "rgb(8, 24, 34)", padding: "3em 0"}}>
            <div style={{ width: '90%', margin: '0 auto'}}>
             <p style={{color: '#fff', fontSize: '14px', textAlign: 'center'}}>Copyright © 2022 Alphaxn. All rights reserved.</p>
            </div>
        </footer> */}
    </div>
  )
}

export default NewTab