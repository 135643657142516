import React from 'react'
import transImg from '../img/transtrain.png'
import train from '../img/traintracks.jpg'
import 'animate.css'

const Train = () => {
  return (
    <section data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" className='Train' id='train'>
        <div className='CornerLeft'></div>
        <div className='CornerRight'></div>
        <div className='maincontent'>
            <div className='bgImage'>
                <span className='styleimage'>
                    <span className='spanstyle1'>
                        <img className='trainimg' alt aria-hidden='True' src={transImg}></img>
                    </span>
                    <img alt='image' src='imgicon/bull_g2.jpg' decoding="async" data-nimg='intrinsic' className='bgimagefull'
                    srcSet='imgicon/bull_g2.jpg'></img>
                </span>
            </div>
            <div className='ContStyle container' >
                <h3 data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='400'></h3>
                {/* <div className='line2'></div> */}
                <div className='traincontent' style={{ paddingTop: '26em'}}>
                    <h3 className='greentxt' data-aos='fade-up' data-aos-duration="500" data-aos-once="True" data-aos-delay="100"><span style={{color: '#34a853',textTransform: 'none',letterSpacing: '1.16px',fontWeight: 'bolder'}}><b>ALPHAXN was established to be a trusted and reliable partner to private capital investment firms.</b></span></h3>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Train