import React from 'react'
import ContactUs from './ContactUs'
import MountBg from './MountBg'
import SecondLast from './SecondLast'
import Train from './Train'
import 'animate.css'
import Hero from './Hero'
import Navbar from './Navbar'
import NavbarTest from './NavbarTest'
import Cokies from './Cokies'
// import cookie from './cookie.css'
import Cookies from 'js-cookie';
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios';
import Testimonials from './Testimonials'

// Cookies.set('name', 'value', { expires: 7 });
// const value = Cookies.get('name');

function Home() {
  
  // const [user, setUser] = useState({});

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const response = await axios.get('/api/user');
  //     setUser(response.data);
  //     Cookies.set('user', JSON.stringify(response.data), { expires: 7 });
  //   };
  //   fetchUser();
  // }, []);

  // useEffect(() => {
  //   const user = Cookies.get('user');
  //   if (user) {
  //     setUser(JSON.parse(user));
  //   }
  // }, []);
    
  return (
    <div>
        <Cokies/>
        <Hero/>
        <Navbar/>
        <NavbarTest/>
        <MountBg/>
        <SecondLast/>
        <Testimonials/>
        <Train/>
        <ContactUs/>
    </div>
  )
}

export default Home