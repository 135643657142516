import React from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

const ContactTab = () => {
  return (
    <div id='main'>
        <header className='headerstyle'>
            <nav className='nav1 container'>
                <div className='logoimg'>
                    <div>
                        <span className='logostyle'>
                            <span style={{boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px', maxWidth: '100%'}}>
                                <img alt='logo' src='imgicon/logo.png' style={{display: 'block',  maxWidth: '100%', width: 'initial', height: 'initial', background: 'none',  opacity: '1',  border: '0px', margin: '0px', padding: '0px'}}></img>
                            </span>
                            <img alt='log' srcSet='imgicon/logo.png' style={{position: 'absolute', inset: '0px', boxSizing: 'border-box', padding: '0px', border: 'none', margin: 'auto', display: 'block', width: '0px', height: '0px', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}></img>
                        </span>
                    </div>
                </div>
                <div className='navtabs'>
                    <ul className='tab1'>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link to='/'>Home</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link to='/about'>About us</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link to='/services'>services</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link to='/industries'a>industries</Link></li>
                        <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}><Link to='/contact'>contact us</Link></li>
                        {/* <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}}>About</li> */}

                    </ul>
                </div>
            </nav>
        </header>
        {/* continue here */}
        <main >
            <section className='bannerstyle'>
                <div className='justdiv'>
                    <div className='leftban'>
                        <h1>
                            <span style={{paddingRight: '90px',color: 'white'}}>Contact us</span>
                            <div style={{ width: '40px', position: 'absolute', top: '50%', right: '0', maxWidth: '40px', height: '1px', backgroundColor: '#fff'}}></div>
                        </h1>
                    </div>
                    <div className='leftbgimg'>
                        <div style={{height: '100%'}}>
                            <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%'}}>
                                <span className='imagebgleft'>
                                    <motion.img animate={{scale:1.2}} transition={{duration: 3}} alt='city' srcSet='imgicon/contact.jpg' className='bgimagecity' decoding='sync'></motion.img>
                                </span>
                            </div>
                            <div className='transparent image'></div>
                        </div>
                        <div className='cornerleft1'></div>
                    </div>
                </div>
                <div style={{position: 'relative', width: '100%', height: '100%', backgroundColor: '#081822'}}>
                    <div className='rightcontent1'>
                        <span className='spancontent1'>
                            <span></span>
                        </span>
                    </div>
                    <div className='righttri'></div>
                </div>
            </section>
            <section style={{position: 'relative', zIndex: '1'}}>
                
            <div className='FootEr'>
      <div className='FooterBox container'>
        <h3>CONTACT US</h3>
        <div className='FooterContent'>
          <div className='Divider' data-aos="slide-down" data-aos-once="True" data-aos-delay="200" style={{height: "100%"}}></div>
          <div className='LeftContent'>
            <div className='FootTxt'>
              <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400"><span>For all inquiries, please email us or use the form to the right.</span></p>
              <div className='UnderLine' style={{width: "100px"}}></div>
              <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="500" style={{margin: "2.5em 0"}}><a href='abcd@gmail.com'>abcdj@gmail.com</a></p>
              <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="600" style={{margin: "2.5em 0"}}>123 iiit hyd ,suite 702 , hyderabad telangana</p>
              <small className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="700"  style={{margin: "2.5em 0"}}><span style={{ position: "relative", lineheight: "1.2rem",opacity: ".5", fontweight: "100" ,fontsize: ".8rem" ,maxwidth: "350px", marginleft: "auto"}}>lorem ipsum text dsfasdfThe information contained in this web site is for information purposes only and should not be construed as financial or investment advice on any subject matter. Saluda Grade does not warrant the accuracy of the information provided on this web site</span></small>
            </div>
          </div>

          {/* right side */}
          <div className='RightContent' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400">
              <h4>Explore the alternatives</h4>
              <div>
                <form>
                  <div className='Form1'>
                    <div className='Form12'>
                      {/* <label>name</label> */}
                      <input className='InputBox' type='text' placeholder='name' style={{backgroundimage:"none"}} name='name'></input>
                    </div>
                    <div className='Form12'>
                      {/* <label>surname</label> */}
                      <input className='InputBox' type='text' placeholder='surname' style={{backgroundimage:"none"}} name='surname'></input>
                    </div> 
                  </div>
                  <div className='FormEmail'>
                    {/* <label>Email</label> */}
                    <input className='InputBox' type="Email" placeholder='Email' name='Email'></input>
                  </div>
                  <div className='FormEmail'>
                    <textarea className='InputBox' placeholder='Message' name="message"></textarea>
                  </div>
                  <div style={{paddingtop: "2em"}}>
                    <button type="submit" className='FormButton'>
                      <span  style={{position: "relative", letterspacing: ".1em"}}>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </div>

    </div>

            </section>
            {/* <section className='cutimage'>
                <div className='cutimage1'>
                    <div className='emptybg'></div>
                    <span className='imagestyle1'>
                        <span className='insidespan'>
                            <img alt='image' srcSet='imgicon/city.jpg' style={{display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px'}}></img>
                        </span>
                        <img alt='city' className='imagejpg' decoding='async' src='imgicon/city.jpg'></img>
                    </span>
                </div>
            </section> */}
        </main>
        <footer className='FooterStyle' style={{backgroundColor: "rgb(8, 24, 34)", padding: "3em 0"}}>
            <div style={{ width: '90%', margin: '0 auto'}}>
             <p style={{color: '#fff', fontSize: '14px', textAlign: 'center'}}>Copyright © 2022 Alphaxn. All rights reserved.</p>
            </div>
        </footer>
    </div>
  )
}

export default ContactTab