import React from 'react'

const SecondLast = () => {
  return (
    <section className='secondlasttxt container-small' id='industries'>
        <div style={{paddingTop: '2em', paddingBottom: '2em'}}></div>
        <h3 data-aos='fade' data-aos-duration='700' data-aos-once='True' data-aos-delay='50'><b>Industries</b></h3>
        <h4 data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="70" style={{textAlign: 'center', marginTop: '56px'}}>
            <span>We serve</span>
        </h4>
        <div className='finalline'></div>
        <div className='finaltxtbox'>
            <div >
                <h4 className='pe1' data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" >
                    <span>Private Equity Funds</span>
                </h4>
                {/* <p data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="150" style={{paddingBottom: '2em', fontSize: '1.2em', letterSpacing: '.45px' , lineHeight: '1.6em', fontFamily: '"Raleway", sans-serif'}}></p> */}
            </div>
            <div >
                <h4 data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{position: "realtive", overflow: "hidden",paddingBottom: '1.5em'}}>
                    <span>Real Estate Invesment Funds</span>
                </h4>
                {/* <p data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="150" style={{paddingBottom: '2em', fontSize: '1.2em', letterSpacing: '.45px' , lineHeight: '1.6em', fontFamily: '"Raleway", sans-serif'}}></p> */}
                 {/* <div className='tetlast'>
                    <button type="button" class="btn btn-link">Read more</button>
                </div> */}
            </div>
            
            {/* <div>
                <h4 className='pe' data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" >
                    <span>Real Estate Investment</span>
                </h4>
                <p data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="150" style={{paddingBottom: '2em', fontSize: '1.2em', letterSpacing: '.45px' , lineHeight: '1.6em', fontFamily: '"Raleway", sans-serif'}}></p>
            </div>
            <div>
                <h4 data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{position: "realtive", overflow: "hidden"}}>
                    <span>Hedge Funds</span>
                </h4>
                <p data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="150" style={{paddingBottom: '2em', fontSize: '1.2em', letterSpacing: '.45px' , lineHeight: '1.6em', fontFamily: '"Raleway", sans-serif'}}></p>
                 
            </div> */}
        </div>
    </section>
  )
}

export default SecondLast