import React from 'react'
import { Link } from 'react-router-dom'


const MountBg = () => {
  return (
    <>
    <div style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: '#fafcfa'}} id='about1'></div>
    <div></div>
    <div className='mountbg'>
      <section className='sec1' id='about'>
        <div className='sec1cont'>
          <h3 data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='100'><b>about us</b></h3>
          <div className='line3' style={{height: '165%'}}></div>
          <div className='mountbgtxt'>
            <div style={{textAlign: 'right'}}>
              <h4 data-aos='fade-up' data-aos-duration="700" data-aos-once="True" ><span><b>Get on top of it and stay. Always.</b></span></h4>
            </div>
            <div data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="110">
              <p className='parah' style={{marginBottom: '0em'}}>
                <b style={{position: 'relative',top: '-11px',lineHeight: '2.4'}}>We provide financial and accounting services to Private Equity and Real Estate funds.</b>
                <p></p><br></br><br></br>
                <p style={{marginBottom: '0em',position: 'relative', top: '-36px'}}>ALPHAXN team delivers best-in-class service everyday to support the function of<br></br> Analysts and Accountants.</p>
                {/* <p style={{position: 'relative', top: '-36px'}}>Analysts and Accountants.</p> */}
              </p>
              <button type="submit" className='FormButton more_btn'>
                <span  style={{position: "relative", letterspacing: ".1em", color: '#ed462f'}}><Link style={{color: '#34a853'}} to='/aboutus'><b>More</b></Link></span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='sec2' id='services1'>
        <div className='linesmall'></div>
        <div className='shapecurve'></div>
        <div className='mtbody'>
          <div className='container' id='services'>
            <div ></div>
            {/* <h3 data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='400'>Services</h3> */}
            <div style={{paddingTop: '2em', paddingBottom: '4em'}} id='services1'> 
              {/* <p data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400" style={{fontWeight: '400'}} className='slogan'>To address these challenges, we offer multiple services to the private equity firms and hedge funds.</p> */}
              {/* <button type="submit" className='FormButton'>
                <span  style={{position: "relative", letterspacing: ".1em"}}>Learn more</span>
              </button> */}
            </div>
            <h3 style={{paddingBottom: '6em',fontWeight: '600'}} data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='100'><b>Services</b></h3>
            
            <div className='newgrid' style={{paddingTop: '1em'}}>
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><img className='imgwidth' alt='1' src='imgicon/services2/1.png'></img></div>
                {/* <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="30"><img className='imgwidth' alt='2' src='imgicon/services2/2.png'></img></div> */}
                {/* <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="40"><img className='imgwidth' alt='3' src='imgicon/services2/3.png'></img></div> */}
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><img className='imgwidth' alt='4' src='imgicon/services2/dt.png'></img></div>
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="30"><img className='imgwidth' alt='5' src='imgicon/services2/5.png'></img></div>
                {/* <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="40"><img className='imgwidth' alt='6' src='imgicon/services2/6.png'></img></div> */}
                <div></div>
                
                <div>
                  {/* <img src='imgicon/sericon2/1.png'></img>
                  <p>Deal Team Augmentation</p> */}
                </div>

                <button type="submit" className='FormButton'>
                  <span className='moreser'><Link style={{color: '#34a853'}} to='/services'><b>More</b></Link></span>
                </button>
            </div>

            {/* responsive */}
            <div className='newgrid_ipad' style={{paddingTop: '1em'}}>
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><img className='imgwidth_ipad' alt='1' src='imgicon/ipad/dta.png'></img></div>
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><img className='imgwidth_ipad' alt='4' src='imgicon/ipad/dma.png'></img></div>
                <div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="30"><img className='imgwidth_ipad' alt='5' src='imgicon/ipad/acc.png'></img></div>                <div></div>
                
                <div>
                  {/* <img src='imgicon/sericon2/1.png'></img>
                  <p>Deal Team Augmentation</p> */}
                </div>

                <button type="submit" className='FormButton'>
                  <span className='moreser'><Link style={{color: '#34a853'}} to='/services'><b>More</b></Link></span>
                </button>
            </div>
            
            <div className='itembox'>
              {/* <div style={{dispaly: 'none'}}></div> */}  
              
              

              
              <div></div>
              <div></div>
              
              
            </div>  {/*intembox2*/}
          </div>
          <div className='line4'></div>
        </div>
        <div className='traiangleMid'></div>
        <svg className='cornerimg' viewBox='0 0 500 500' preserveAspectRatio='none'>
          <mask id='svgmask1'>
            <polygon points='0,250 500,0 500,500' fill='#ffffff'></polygon>
          </mask>
          <image xlinkHref='imgicon/people_new.jpg' mask='url(#svgmask1)' width='200%' height='300%' y='-120%' x='-60%' preserveAspectRatio='none'></image>
        </svg>
        <div className='bottomtraingle'></div>
      </section>
      <div className='mountbgimg'>
        <span className='mountimage'>
          <img alt='mount' style={{opacity: '.9'}} sizes='100vw' src='imgicon/mount_green.png' decoding='async'></img> 
        </span>
      </div>
    </div>
    </>
  )
}

export default MountBg