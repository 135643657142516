import React from 'react'
import 'animate.css';
import {motion} from 'framer-motion'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

function Hero() {
    return (
    
    <section className='bgwrapper' id='home'>
        {/* <Navbar/> */}
        <div data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" className='content' style={{ zIndex: '1' }}>
            <div className='mobileview'>
                <h1><span className='container' style={{ textAlign: 'center', fontFamily: '"Raleway", sans-serif', fontWeight: '900' }}><b style={{ fontSize: '50px' }}>ALPHAXN</b></span></h1>
                <p className='herotxt'><b>Analyst and Accounting Teams for PE & Real Estate Funds</b></p>
                <motion.a whileHover={{ scale: 1.1, textShadow: "0px 0px 8px #fff", boxShadow: "0px 0px 8px #fff", fill: '#34a853', backgroundColor: 'transparent', color: '#fff', border: '2px solid ##34a853' }} className='btnbtn' href='#about' style={{ color: 'white' }}><b>Get Started</b></motion.a>
            </div>

            {/* <motion.div initial={{x: -200}} animate={{x: 0}} transition={{duration: 1.2}} style={{width: '570px', height: '300px', backgroundColor: 'rgb(52, 168, 83, 0.7)',position: 'absolute',zIndex: '12',left: '196px',top: '188px'}}>
                <h1 data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" style={{textAlign: 'center' ,textAlign: 'center', fontFamily: '"Raleway", sans-serif', fontWeight: '900',display: 'block',paddingTop: '1em',paddingBottom: '13px'}}>ALPHAXN</h1>
                <p data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" style={{ textAlign: 'center', color: 'white', fontFamily: '"Raleway", sans-serif', fontSize: '20px',padding: '-3em',paddingBottom: '24px' }}><b>Analyst and Accounting Teams for PE & Real Estate Funds</b></p>
            <motion.a whileHover={{ scale: 1.1, textShadow: "0px 0px 8px #fff", boxShadow: "0px 0px 8px #fff", fill: '#34a853', backgroundColor: 'transparent', color: '#fff', border: '2px solid ##34a853' }} className='btnbtn' href='#about' style={{ color: 'white',paddingBottom: '1em'}}><b>Get Started</b></motion.a>
            </motion.div> */}

        </div>
        <div className='wrapperstyle'>
            {/* <div></div> */}

            {/* <div data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" className='content' style={{zIndex: '1'}}>
                <div style={{bottom: '177px',position: 'absolute'}}>
                <h1><span className='container' style={{ textAlign: 'center', fontFamily: '"Raleway", sans-serif', fontWeight: '900'}}><b style={{fontSize: '50px'}}>ALPHAXN</b></span></h1>
                <p style={{textAlign: 'center',color: 'white',fontFamily: '"Raleway", sans-serif', fontSize: '28px'}}><b>Solutions for Private Capital Investment Firms</b></p>
                <motion.a whileHover={{scale: 1.1, textShadow: "0px 0px 8px #fff", boxShadow: "0px 0px 8px #fff",fill: '#34a853', backgroundColor: 'transparent', color: '#fff', border: '2px solid ##34a853' }} className='btnbtn' href='#about' style={{color: 'white'}}><b>Get Started</b></motion.a>
                </div>
            </div> */}
            {/* text */}
            {/* <Navbar/> */}
            <div className='wrapperbg '>
                <motion.span className='bgwrapperspan' animate={{scale:1.2}} transition={{duration: 2,delay: 1.2}}>
                    <span className='bgwrapperspan1'>
                        <img alt='bg' src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271968%27%20height=%271312%27/%3e' className='wrapperimg'></img>
                    </span>
                    {/* <motion.div initial={{x: -200}} animate={{x: 0}} transition={{duration: 1.2}} style={{width: '424px', height: '300px', backgroundColor: 'rgb(52, 168, 83, 0.5)',position: 'absolute',zIndex: '12',left: '288px',top: '322px'}}>
                        <h1 data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" style={{textAlign: 'center' ,textAlign: 'center', fontFamily: '"Raleway", sans-serif', fontWeight: '900',display: 'block',padding: '1em'}}>ALPHAXN</h1>
                        <p data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="1000" style={{ textAlign: 'center', color: 'white', fontFamily: '"Raleway", sans-serif', fontSize: '20px',padding: '1em' }}><b>Analyst and Accounting Teams for PE & Real Estate Funds</b></p>
                        <motion.a whileHover={{ scale: 1.1, textShadow: "0px 0px 8px #fff", boxShadow: "0px 0px 8px #fff", fill: '#34a853', backgroundColor: 'transparent', color: '#fff', border: '2px solid ##34a853' }} className='btnbtn' href='#about' style={{ color: 'white' }}><b>Get Started</b></motion.a>
                    </motion.div>  */}
                    <motion.img initial={{y: -900}} animate={{y: 0}} transition={{duration: 1.2}} alt='bg' src='imgicon/green_room_center.jpg' className='wrapperimg1' decoding='async'></motion.img>
                </motion.span>
            </div>
        </div>
        <div style={{position: 'relative'}}>
            <div className='triangleLeft trans'></div>
            <div className='triangleRight trans2'></div>
        </div>
        
    </section>
   

  )
}

export default Hero